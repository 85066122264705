import React from "react";

// reactstrap components
import {
  
  CardTitle,
  
  Row,
  Col,
  
} from "reactstrap";

import "../../aboutus.css"
import 'animate.css';

import ScrollAnimation from 'react-animate-on-scroll';

function PrivacyPolicy() {


  return (
    <>
      <section className="blogs-6" style={{fontFamily: 'Montserrat', marginTop: 0}} >
          <Row style={{justifyContent: 'center'}}>
            <Col xs="10" style={{display: "flex", alignItems: 'center', textAlign: 'center', backgroundColor: 'transparent'}}>
              <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                <div className="wrapper p-md-0">
                  <CardTitle className="display-3" tag="h3" style={{fontFamily: 'Montserrat'}}>
                    Privacy Policy
                  </CardTitle>
                  <div className="lead" style={{fontFamily: 'Montserrat'}}>
                    <p>Updated August 31, 2020</p>
                    <p>This Privacy Policy describes how Helio Logistics, Inc. and its subsidiaries and affiliates (collectively “Helio,” “we,” or “us”) collects, processes, uses, discloses, and secures information through its mobile apps (the “apps”), devices, software applications, websites, including joinhelio.com (the “Sites”) (collectively the “Services”). It relates to the immediate user of the Services (“You” or “User”) and individuals such as human patients and/or their parents or guardians, caretakers of veterinary patients and other visitors (collectively “Care Recipients”).</p>
                    <p>Our Privacy Policy explains:</p>
                    <p>The information we collect, why we need it, and how use it.</p>
                    <p>What choices you can make about how we use your information.</p>
                    <p>The measures we take to protect the security of the information and maintain regulatory compliance for HIPAA, GDPR, and other data regulations.</p>
                    <p>This Privacy Policy applies to Personal Information and all other information that we collect in order to provide the Services. “Personal information” includes any information that can be used on its own or with other information to identify or contact a single person or to identify an individual in context. If we can link particular information (directly or indirectly) to an individual, we will consider this information “Personal Information,” and we will protect it. We value keeping your Personal Information confidential and using it solely in the context of our mission to enable you to become fully engaged in your healthcare through updated personal health records and the most recent information regarding your disease state in order to aid you and your healthcare providers in making informed decisions about your care.</p>
                    <p>The personal information we collect and transmit may include healthcare information, including billing, insurance, and medical information. Therefore, our privacy practices are intended to comply with the health insurance portability and accountability act (“HIPAA”) and General Data Protection Regulation (“GDPR”). We will maintain the privacy of your health information as required by HIPAA and the regulations promulgated under that act.</p>
                    <p>For additional information related to your healthcare information or if you have any questions, please reach out to us at contact@joinhelio.com.</p>
                    <p>BY SUBMITTING YOUR PERSONAL INFORMATION THROUGH THE SERVICES, YOU ARE ACKNOWLEDGING THAT YOU HAVE READ AND AGREE TO THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE, PLEASE DO NOT LOG INTO OR ACCESS THE SERVICES AND DO NOT SUBMIT ANY INFORMATION TO US.</p>
                    <p>Access to and use of the Services by a healthcare provider who is an Helio customer (a “Customer”) and such Customer’s authorized users is subject to and governed by the agreement between Helio and the applicable Customer executed by authorized representatives of each party (the “Customer Agreement”). Helio may collect, use and disclose information from a Customer and such Customer’s authorized users as set forth in the Customer Agreement. If you would like more information about the Services or becoming a Customer, please contact us at contact@joinhelio.com.</p>
                    <p>If you are a patient of one or more of our participating Customers (a “Patient”) and would like to make information related to your health (“Health Data”) available to your Providers through the Services, you will be required to agree to the Terms of Use for the Services and this Privacy Policy.</p>
                    <p>The information we collect, why we need it, and how we use it.</p>
                    <p>We may collect the following types of personal information from users of our Services, and store it on your mobile device, and/or in a secure third-party cloud services provider database:</p>
                    <p>Account Information</p>
                    <p>When you register to use the Service or create an Helio account, we may collect your name and all other information provided to us, such as your email address, password, date of birth or gender. We also collectany information uploaded or otherwise input by you while using the Service, including, but not limited to, information related to medications you are taking and other health-related information about you. You may add information to your profile such as Patient ID, and information about your activity level, medical conditions, and medications. We use this information to create your account and provide you with the Services.</p>
                    <p>If you are a healthcare provider, we may also collect your National Provider Identifier (NPI).</p>
                    <p>Physiologic and Usage Data</p>
                    <p>We collect certain information through your use of the Devices and/or your smartphone connected to the Services, such as but not limited to: heart sound data, lung sound data, ECG data, diagnosed condition, blood oxygen levels, other vitals, mobile device accelerometer data, average heart rate, the location on the body where the recording was taken, local time, time zone and geographic location of data acquisition. We may collect such information from patients or from providers. We use this information to provide and improve the Services.</p>
                    <p>Customer Support Inquiries</p>
                    <p>If you contact us directly, such as when you contact our Customer Support team, we will receive the contents of your message or any attachments you may send to us, as well as any additional information you choose to provide. We may also collect technical information about your device, including your IP address or device ID, which we will use to provide you with support and to improve our services. Contact us at contact@joinhelio.com if you have any questions.</p>
                    <p>Payment Information (applicable to Providers ONLY).</p>
                    <p>We use third-party payment processors to process any payments you make to us. When you make payments through the Services, you may need to provide your shipping address and financial account information, such as your credit card number, to our third-party service providers. All of the information you provide in connection with making a payment to us is collected and stored by the third-party service provider, not by Helio. We do not collect or store financial information, though we may receive transaction identifiers and summary information that does not include credit card or bank account numbers.</p>
                    <p>Importantly, your use of such third-party payment processors to make payments to Helio is governed by that third-party’s Terms of Use, Privacy Policy, and other applicable Terms. We encourage you to read those terms before using the third-party site and to reach out directly to the third-party if you have questions. HELIO CANNOT BE HELD LIABLE FOR ANY LOSS DAMAGE, THEFT, OR OTHER ACTION ARISING FROM A THIRD PARTY PAYMENT PROCESSOR’S FAILURE TO KEEP YOUR INFORMATION SECURE.</p>
                    <p>Non-Identiﬁable Data Related to Operation of the Service:</p>
                    <p>When You interact with Helio through the Services, We receive and store certain personally non-identiﬁable information. Such information, which We collect passively using various technologies, cannot presently be used to speciﬁcally identify You. We may store such information ourselves or such information may be included in databases owned and maintained by Helio affiliates, agents or service providers. The Services may use such information and pool it with other information to track, for example, the total number of users of the Services, the number of visitors to each page of Our Site, and the domain names of Our visitors’ Internet service providers</p>
                    <p>Aggregated Personal Data</p>
                    <p>In an ongoing effort to better understand and serve our Customers, other users of the Services and communities of patients with chronic health conditions, Helio conducts research on its user demographics and behavior based on the Personal Information we collect from you and the other information provided to us. This research may be compiled and analyzed on an aggregate basis, and Helio may share this research and related information in aggregated, de-identiﬁed and/or anonymized format with its aﬃliates, agents and other healthcare research and services entities. This aggregate information does not identify you personally. Helio may also disclose aggregated, de-identiﬁed and/or anonymized information in order to describe our business and the Services to current and prospective business partners and Customers, and to other third parties for other lawful purposes.</p>
                    <p>Monitoring</p>
                    <p>Helio and its affiliates and agents are permitted, but not obligated, to review and/or retain information and/or communications stored and/or transmitted using the Services (“User Content”). We may monitor User Content for data collection purposes and/or to evaluate the quality of service you receive, your compliance with the Terms of Use, the security of the Services, or for other reasons.</p>
                    <p>If you are a patient, your healthcare provider(s) may also monitor User Content in order to monitor your progress and overall condition and to follow up with you, as they deem appropriate in their independent judgment as your healthcare providers.</p>
                    <p>You agree that such monitoring activities, if in compliance with applicable privacy laws, will not entitle you to any cause of action or other right with respect to the manner in which Helio or its affiliates or agents monitor your communications and enforces or fails to enforce the Terms of this agreement. In no event will Helio or any of its affiliates or agents be liable for any costs, damages, expenses, or any other liabilities incurred by you as a result of monitoring activities by Helio or its affiliates or agents.</p>
                    <p>Device and ISP Data</p>
                    <p>We use common information-gathering tools, such as log files, cookies and similar technologies to automatically collect information, which may contain Personal Information, from your computer or mobile device as you navigate our websites or interact with emails we have sent you. As is true of most websites, we gather certain information automatically via log files. This collected information may include your Internet Protocol (IP) address (or proxy server), device and application identification numbers, your location, your browser type, your Internet service provider and/or mobile carrier, the pages and files you viewed, your searches, your operating system and system configuration information, and date/time stamps associated with your usage. This information is used to analyze overall trends, to help us provide and improve our websites and to guarantee their security and continued proper functioning. We also collect IP addresses from users when they log into the services as part of the Company’s security features.</p>
                    <p>How We Use the Information We Collect</p>
                    <p>To Provide and Improve our Services.</p>
                    <p>We use your information to:</p>
                    <p>provide, evaluate, and improve the Services, including to provide you with heart sound analysis, lung sound analysis, and ECG analysis services and reports based on the analysis of your health-related information, including your physiologic data, Health Data, and data from third-party devices and services;</p>
                    <p>analyze our products and their usage to enhance and improve our existing Service; to develop new products and services; manage our communications; and</p>
                    <p>perform accounting, auditing and other internal functions.</p>
                    <p>‍</p>
                    <p>To Communicate with You</p>
                    <p>We may send you emails, text messages, and push notifications to your mobile device, if you have them enabled, to verify your account and for informational and operational purposes, such as account management, providing instructions, alerts, reminders, customer service, system maintenance, and other Service-related purposes. We may also permit users, such as your health care providers, to use the Services to send you emails, text messages, and push notifications.</p>
                    <p>Marketing and Data Analysis</p>
                    <p>To the extent permitted by applicable law, we may use your information to provide online advertising on the Services and to send you newsletters, offers, surveys, and other promotional information related to Helio products and services. Where required under applicable law, we will obtain appropriate consent to send you marketing communications. You may opt out of email marketing by using the unsubscribe link in a marketing email, or by contacting us at contact@joinhelio.com.</p>
                    <p>How We Share the Information We Collect</p>
                    <p>We consider your information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Information with certain third parties without further notice to you. Those circumstances are described below:</p>
                    <p>With Our Customers: If you are a Patient, we will share your Personal Information and Health Data with our Customer(s) that provide healthcare services to you. This will enable your healthcare provider to track your Health Data and combine such Health Data with other information about you that your provider obtains in treating you.</p>
                    <p>With Patient-Authorized Persons: If you are a Patient, you may have the option of identifying family and/ or friends in the Helio application to view certain of your information and receive alerts regarding your health and/or activities (“Permissions”). If you designate Permissions, we may make available certain of your Personal Information and Health Data, and related alerts, to the people you designate.</p>
                    <p>In the Event of a Business Transfer: We might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Information may be part of the transferred assets.</p>
                    <p>With Related Companies: We may also share your Personal Information with Helio Related Companies for purposes consistent with this Privacy Policy.</p>
                    <p>With Our Agents, Consultants and Related Third Parties: Helio, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include data hosting and billing management. When we employ another entity to perform a function of this nature, we only provide the entity with the information that it needs to perform its speciﬁc function.</p>
                    <p>To Meet Our Legal Requirements: We may disclose your Personal Information if required to do so by law or if we have a good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend our rights or property, (iii) act in urgent circumstances to protect the personal safety of you, us, other users of the Services or the public, or (iv) protect against legal liability.</p>
                    <p>NOTE: We may, from time to time, rent or sell aggregated data and/or other information that does not contain any personal identifiers (i.e., if the information has been anonymized by stripping out identifiers such as name, address, phone number, etc.). The purpose of this type of disclosure is to allow research institutions to learn more about symptoms associated with your medical condition(s).</p>
                    <p>Cookies and Analytics Technologies</p>
                    <p>When you visit our Service or open our emails, we and our third-party service providers may collect certain information by automated means, such as cookies, web beacons and web server logs. A cookie is a piece of information that the computer that hosts our Services gives to your browser when you access the Services. Our cookies help provide additional functionality to the Services and help us analyze Services usage more accurately for research and marketing purposes. In all cases in which we use cookies, we will not collect Personal Information except with your permission. In addition to cookies, we may use web beacons (also known as “clear GIFs”) to measure traﬃc to or from the Services and related browsing behavior and to improve your experience when using the Services.</p>
                    <p>The information collected in this manner includes IP address, browser characteristics, device IDs and characteristics, operating system version, language preferences, referring URLs, and information about the usage of our Service. We may link this data to your profile. You may be able to change browser settings to block and delete cookies when you access the Sites through a web browser. However, if you do that, the Sites may not work properly. Our ad networks and analytics service providers may also collect information about your use of other websites and online services over time, if those websites and online services also use the same service providers.</p>
                    <p>HIPAA Compliance</p>
                    <p>Notwithstanding anything in this Privacy Policy to the contrary, to the extent we create, receive, maintain, or transmit (collectively, “Process”) “protected health information” (as such term is defined in 45 C.F.R 160.103) in providing the Service, we shall only use and disclose that information in accordance with the Health Insurance Portability and Accountability Act of 1996, as amended, and its implementing regulations (collectively, “HIPAA”). HIPAA also requires us to, among other things, apply reasonable and appropriate measures to safeguard the confidentiality, integrity, and availability of the protected health information we Process.</p>
                    <p>For Patients: Under HIPAA, your healthcare provider is generally required to provide or make available to you a Notice of Privacy Practices (“NPP”). The NPP is intended to explain to you ways in which your healthcare provider may use and share your protected health information and inform you about your health privacy rights. For more information about how your healthcare provider uses and shares your information, ask your healthcare provider for a copy of their NPP. HELIO IS NOT RESPONSIBLE FOR YOUR HEALTHCARE PROVIDER’S USE OR SHARING OF YOUR INFORMATION.</p>
                    <p>Controller</p>
                    <p>Helio processes Personal Data both as a Processor and as a Controller, as defined in the Directive and the GDPR.</p>
                    <p>Helio is a US-based company; we do not have locations in the EU nor do we store any data in the EU. If you are an EU citizen located in the EU, all of your data will be transmitted to Helio in the US and stored on US-based servers in compliance with US regulations.</p>
                    <p>All data collected by Helio will be stored exclusively in secure hosting facilities. Helio has a data processing agreement in place with its provider. All hosting is performed in accordance with the highest security regulations.</p>
                    <p>Your Rights and Choices</p>
                    <p>We offer you certain choices in connection with the information we collect about you.</p>
                    <p>Subject to applicable law, you may have the right to request access to and be informed about the information we maintain about you, update and correct inaccuracies in your information, and have the information blocked or deleted, as appropriate. If you wish to request access or an update to the information that we have concerning you, please email us at contact@joinhelio.com</p>
                    <p>Your rights to your information may be limited in some circumstances by local legal requirements. You also have the right to withdraw your consent to the collection of your information. Note however that if you exercise your right of blocking or deletion, if you decline to share certain information with us, or if you withdraw your consent, we may not be able to provide to you some of the features and functionalities of the Service.</p>
                    <p>You may contact us as indicated in the ‘How to Contact Us’ section of this Privacy Policy to exercise your rights and choices to your information.</p>
                    <p>EU DATA SUBJECT RIGHTS</p>
                    <p>If you are an EU data subject, you have the following rights under certain circumstances:</p>
                    <p>to receive communications related to the processing of your personal data that are concise, transparent, intelligible and easily accessible;</p>
                    <p>to be provided with a copy of your personal data held by us;</p>
                    <p>to request the rectification or erasure of your personal data held by us without undue delay;</p>
                    <p>to request that we restrict the processing of your personal data (while we verify or investigate your concerns with this information, for example);</p>
                    <p>to object to the further processing of your personal data, including the right to object to marketing;</p>
                    <p>to request that your personal data be moved to a third party;</p>
                    <p>to receive your personal data in a structured, commonly used and machine-readable format;</p>
                    <p>to lodge a complaint with a supervisory authority.</p>
                    <p>Where our processing of your Personal Information is based on consent, you have the right to withdraw that consent without detriment at any time by contacting us at contact@joinhelio.com. You can also exercise the rights listed above at any time by contacting us at contact@joinhelio.com.</p>
                    <p>Helio users may also contact us to:</p>
                    <p>– Stop the sharing of your information with a specific provider;</p>
                    <p>Update your email preferences or ask us to remove your information from our mailing lists; or</p>
                    <p>– Submit another type of request</p>
                    <p>Data Retention and Deletion</p>
                    <p>We store your Personal Information for as long as you maintain an account and up to five (5) years after the account is closed. At the end of this five-year period, we may remove your Personal Information from our databases and will request that our business partners remove your Personal Information from their databases. When we delete any information, it will be deleted from the active database, but may remain in our archives. However, once we disclose your Personal Information to third parties, we may not be able to access that Personal Information any longer and cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures. Written requests for deletion of Personal Information other than as described should be directed to contact@joinhelio.com. We retain anonymized data indefinitely.</p>
                    <p>We will continue to use de-identified and/or aggregated information, as permitted under applicable law and to comply with our legal obligations, agreements with physicians and healthcare providers, resolve disputes, enforce our rights, or similar purposes. You may delete the App or software to remove information stored on your device.</p>
                    <p>Not all Care Recipients are aware of their Rights when using the Services. It is up to You, as the User to inform those Care Recipients of their Rights according to this Privacy Policy.</p>
                    <p>Data Sharing Confirmation</p>
                    <p>To facilitate secure sharing of data to a health professional, Helio Devices may contact you by email to confirm a request to do so. You have the ability to accept or reject those requests. If you wish to retract sharing of your data, please submit a request via the ‘How to Contact Us’ section of this Privacy policy.</p>
                    <p>Data Transfers</p>
                    <p>We may transfer personal information we collect about you to countries other than the country in which the personal information originally was collected. Those countries may not have the same data protection laws as the country in which you initially provided the personal information. When we transfer your personal information to other countries, we will put in place measures to adequately protect that personal information as described in this Privacy policy so that same level of protection is applied to that personal information as would be required were it processed in the country in which the personal information was originally collected. If you are located in the European Economic Area (“EEA”), please note that we have implemented safeguards to ensure your Personal Information is protected when transferred, in accordance with applicable data transfer restrictions.</p>
                    <p>How We Protect Personal Information</p>
                    <p>We maintain administrative, technical and physical safeguards designed to protect the personal information you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while Helio uses reasonable efforts to protect your information, we cannot guarantee its absolute security.</p>
                    <p>How You Can Protect Your Personal Information</p>
                    <p>We will NEVER send you an e-mail requesting confidential information such as account numbers, usernames, passwords, or social security numbers, and you should NEVER respond to any e-mail requesting such information. If you receive such an e-mail purportedly from Helio, DO NOT RESPOND to the e-mail and DO NOT CLICK on any links and/or open any attachments in the e-mail, and notify Helio support at contact@joinhelio.com</p>
                    <p>You are responsible for taking reasonable precautions to protect your user ID, password, and other account information from disclosure to third parties, and you are not permitted to circumvent the use of required encryption technologies. You should immediately notify Helio at contact@joinhelio.com if you know of or suspect any unauthorized use or disclosure of your user ID, password, and/or other User Account information, or any other security concern.</p>
                    <p>Updates to Our Privacy Policy</p>
                    <p>This Privacy Policy may be changed or updated periodically and without prior notice to you. When we change this Privacy Policy, we will notify you of the changes by posting a notice on our Services and indicate at the top of the policy when it was most recently updated. Where required by law, we will seek your explicit consent to specific changes. You agree that Helio Devices will reserve the right to occasionally notify you via email of any important changes to this Privacy policy and/or our Terms of Use.</p>
                    <p>Information Submission by Minors</p>
                    <p>We do not knowingly collect personal information from individuals under the age of 18 and the Services are not directed to individuals under the age of 13. We request that these individuals not provide personal information through the Services. If you are aware of a user under the age of 13 using the Services, please contact us at contact@joinhelio.com.</p>
                    <p>Privacy Shield</p>
                    <p>Helio complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States. Helio has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit https://www.privacyshield.gov/.</p>
                    <p>In compliance with the Privacy Shield Principles, Helio commits to resolve complaints about our collection or use of your personal information. EU and Swiss individuals with inquiries or complaints regarding our Privacy Shield policy should first contact Helio at:</p>
                    <p>Helio Logistics, Inc.</p>
                    <p>Attn: Privacy Officer</p>
                    <p>272 W Park Avenue</p>
                    <p>Long Beach, NY 11561</p>
                    <p>contact@joinhelio.com</p>
                    <p>Helio has further committed to cooperate with the panel established by the EU data protection authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (FDPIC) with regard to unresolved Privacy Shield complaints concerning data transferred from the EU and Switzerland, including human resources data in the context of the employment relationship.</p>
                    <p>Please note that if your complaint is not resolved through these channels, under limited circumstances, a binding arbitration option may be available before a Privacy Shield Panel.</p>
                    <p>The Federal Trade Commission has jurisdiction with enforcement authority over Helio's compliance with the Privacy Shield.</p>
                    <p>The Privacy Shield Principles describe Helio's accountability for personal data that it subsequently transfers to a third-party agent. Under the Privacy Shield Principles, Helio shall remain liable if third party agents process the personal information in a manner inconsistent with the Privacy Shield Principles, unless Helio proves it is not responsible for the event giving rise to the damage.</p>
                    <p>Note that Helio may be required to release the personal data of EU and Swiss individuals pursuant to the Privacy Shield in response to legal requests from public authorities including to meet national security and law enforcement requirements.</p>
                    <p>How to Contact Us</p>
                    <p>Helio has a Data Protection Officer and Privacy Officer who is responsible for matters relating to privacy and data protection and who can be contacted at the information below.</p>
                    <p>If you have any questions or comments about this Privacy policy, or if you would like us to update information we have about you or your preferences, please contact us by email at privacy@joinhelio.com. You also may write to</p>
                    <p>Helio Logistics, Inc.</p>
                    <p>Attn: Privacy Officer</p>
                    <p>272 W Park Avenue</p>
                    <p>Long Beach, NY 11561</p>
                  </div>




                </div>
              </ScrollAnimation>
            </Col>
          </Row>
      </section>
    </>
  );
}

export default PrivacyPolicy;
