import React from "react";

// reactstrap components
import {
  
  CardTitle,
 
  Row,
  Col,
  
} from "reactstrap";

import "../../aboutus.css"
import 'animate.css';

import ScrollAnimation from 'react-animate-on-scroll';

function TermsConditions() {

  return (
    <>
      <section className="blogs-6" style={{fontFamily: 'Montserrat', marginTop: 30}} >
          <Row style={{justifyContent: 'center'}}>
            <Col xs="10" style={{display: "flex", alignItems: 'center', textAlign: 'center', backgroundColor: 'transparent'}}>
              <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                <div className="wrapper p-md-0">
                  <CardTitle className="display-3" tag="h3" style={{fontFamily: 'Montserrat'}}>
                    Terms of Service
                  </CardTitle>
                  <div className="lead" style={{fontFamily: 'Montserrat'}}>
                    <p>Updated August 31, 2020</p>
                    <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY CLICKING “ACCEPT” OR ACCESSING THE SERVICE, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF SERVICE, YOU MAY NOT ACCESS OR USE THE SERVICE. BY ACCEPTING THESE TERMS OR BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS, INCLUDING THE Helio PRIVACY POLICY (TOGETHER, THE “TERMS”).</p>
<p>By continuing to use the Services, you agree as follows:</p>
<p>Any information that We collect through your use of the Services is subject to the Helio Privacy Policy (https://joinhelio.com/privacy), which is part of these Terms of Use.</p>
<p>You are at least 13 years old;</p>
<p>You understand and intend that this Agreement is a legally binding agreement and the equivalent of a signed, written contract;</p>
<p>You will use the Services in a manner consistent with applicable laws and regulations and these Terms of Use, as they may be amended by Helio from time to time; and</p>
<p>You understand, accept, and have received these Terms, and acknowledge and demonstrate that you can access these Terms at will.</p>
<p>IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. DO NOT LOG IN TO THE SYSTEM AND IMMEDIATELY DELETE ALL INSTALLED FILES, IF ANY, OF THE ACCOMPANYING SERVICES AND MATERIALS FROM YOUR COMPUTER OR MOBILE DEVICE.</p>
<p>THE HELIO MOBILE APPLICATIONS, HELIO DEVICES AND WEB SERVICES (HEREAFTER, THE “APPLICATION”) ARE AVAILABLE TO USERS IN THE UNITED STATES OF AMERICA (USA) AND CERTAIN OTHER COUNTRIES, THE APPLICATION IS INTENDED TO BE USED AS A PART OF A PHYSICAL ASSESSMENT OR MONITORING OF A PATIENT BY HEALTHCARE PROFESSIONALS FOR DIAGNOSTIC DECISION SUPPORT.</p>
<p>ARBITRATION NOTICE: EXCEPT IF YOU OPT-OUT AND EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND HELIO WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION. YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. YOU CAN OPT OUT OF THE AGREEMENT TO ARBITRATE BY CONTACTING contact@joinhelio.com WITHIN 30 DAYS OF ACCEPTING THESE TERMS.</p>
<p>Terms of Use</p>
<p>‍</p>
<p>Thank you for choosing to use Helio's Services and device(s) (“Devices”)! These Terms of Use are a legal contract between You (“you/your” or “User”) and Helio Logistics, Inc. and govern your use of the Services and all of the text, data, information, software, graphics, photographs, functionality, and more (all of which We refer to as “Materials”) that We may make available to You through the software. The Service is owned by Helio Logistics, Inc. and its subsidiaries or afﬁliates involved in providing and supporting the Services (collectively, “We”, “Us” or “Helio”). The Website and the Application are all referred to as the “Services” in these Terms.</p>
<p>PLEASE READ THESE TERMS AND OUR PRIVACY POLICY CAREFULLY BEFORE YOU CREATE AN ACCOUNT TO USE THE SERVICES. BY CREATING AN ACCOUNT TO USE THE SERVICES, YOU AGREE THAT YOU HAVE READ, AND AGREE TO BE BOUND BY, THE TERMS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, PLEASE DO NOT CREATE AN ACCOUNT.</p>
<p>Purpose of the Services</p>
<p>If you are a patient, our software is provided to you free of charge on behalf of your healthcare provider (“Provider”). If you are a Provider, our software is provided to you subject to these Terms and any other agreements entered into between You and Helio.</p>
<p>For patients and Providers, the software is provided in order to:</p>
<p>Allow patients and Providers to utilize the Services and Devices,</p>
<p>Automatically transmit a patient’s lung sounds, heart sounds, ECG data, and data analysis to the patient’s Provider, and</p>
<p>Allow patients and providers to access the transmitted data and Helio's wireless, mobile, and web-based management systems and technologies.</p>
<p>NOTE: Helio is not a healthcare provider nor do we provide any healthcare services. We are not a health insurance company and we are not licensed to sell health insurance.</p>
<p>We Do NOT Provide Medical Advice</p>
<p>Our Services provide an online option for Providers and patients to communicate about medical conditions that are not serious or life threatening and do not present an emergency. It is NOT intended for use in connection with active patient monitoring to allow immediate clinical action or continuous monitoring by a health care provider or the patient.</p>
<p>THE SERVICES CANNOT AND ARE NOT DESIGNED, INTENDED OR APPROPRIATE TO REPLACE THE PROVIDER-PATIENT RELATIONSHIP OR TO ADDRESS SERIOUS, EMERGENCY, OR LIFE-THREATENING MEDICAL CONDITIONS AND SHOULD NOT BE USED IN THOSE CIRCUMSTANCES. PATIENTS SHOULD ALWAYS TALK TO THEIR HEALTHCARE PROVIDER(S) FOR DIAGNOSIS AND TREATMENT, INCLUDING INFORMATION REGARDING WHICH DRUGS, THERAPY, OR OTHER TREATMENT MAY BE APPROPRIATE FOR THEM.</p>
<p>Helio is not liable in any way, to patients or to Providers, for any malpractice or substandard treatment a Provider provides. You are using the Services at your own risk.</p>
<p>Patients: If at any time you are concerned about your care or the treatment, or you believe or someone else advises you that you have a serious or life-threatening condition, call 9-1-1 immediately in areas where that service is available, or go to the nearest open clinic or emergency room.</p>
<p>Your Provider will base their medical advice on the personal health data you provide through the Services and the data transmitted from your Devices. If you do not provide complete and accurate personal health data, the medical advice you receive may not be correct. Any content provided or accessed through the Services, including without limitation information provided by Helio's staff in response to questions you may submit through the Services, is for informational purposes only, and is not intended to cover all possible uses, directions, precautions, drug interactions, or adverse effects. Please consult your doctor or other qualiﬁed health care provider if you have any questions about a medical condition, or before taking any drug, changing your diet, or commencing or discontinuing any course of treatment. Never disregard, avoid, or delay in obtaining medical advice from a doctor or other qualified healthcare provider because of something posted on our Services.</p>
<p>Helio DOES NOT ENDORSE OR RECOMMEND ANY PROVIDER, AND WE DO NOT CONFIRM THE CREDENTIALS OF ANY PROVIDERS. WE DO NOT CONTROL THE MEDICAL ADVICE THE PROVIDER GIVES, AND WE DO NOT HAVE ACCESS TO OR USE ANY OF THAT ADVICE. IT IS YOUR RESPONSIBILITY TO SEPARATELY CONFIRM THAT A HEALTHCARE PROVIDER IS PROPERLY LICENSED.</p>
<p>1.0 REGISTRATION; TERM OF REGISTRATION</p>
<p>1.1. Registration through the Application</p>
<p>You must be a registered user with Helio in order to use the Application. You may register through the Application or through the Website. By submitting the information requested in the Application’s online registration form, You may access and use the Application and Website to view certain data pertaining to You as made available by Helio. You may not access or use the Application or Website for any other purpose.</p>
<p>By registering for an account, you represent and warrant:</p>
<p>Your registration data is true, accurate, current, and complete;</p>
<p>You will update your registration as needed data to make sure it is accurate;/li></p>
<p>You meet all of the Eligibility requirements set forth below; and</p>
<p>You are authorized to create an account (either for yourself or on behalf of another person)</p>
<p>DO NOT USE THE SERVICES WHERE PROHIBITED BY LAW. YOU UNDERSTAND THAT YOUR USE OF THE SERVICES MAY INVOLVE OR REQUIRE THE TRANSMISSION OF SIGNIFICANT AMOUNTS OF DATA. YOU ARE RESPONSIBLE FOR ALL DATA CHARGES THAT MAY BE CHARGED BY YOUR WIRELESS CARRIER OR INTERNET SERVICE PROVIDER OR THAT MAY OTHERWISE ARISE FROM YOUR USE OF THE SERVICES.</p>
<p>1.2. Term of Your registration</p>
<p>The termof Your registration will commence as of the date You complete Your online registration form (“Registration Date”) and, unless earlier terminated in accordance with these Terms will continue in perpetuity (“Term”). Notwithstanding the foregoing, Your registration may automatically expire following any period of inactivity associated with Your account in excess of twelve (12) consecutive months.</p>
<p>1.3 Eligibility</p>
<p>You must be at least 13 years of age to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 13 years of age; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with all applicable laws and regulations in your local jurisdiction. If you are using the Service on behalf of an entity, organization, or company, you represent and warrant that you have the authority to bind that organization to these Terms and you agree to be bound by these Terms on behalf of that organization.</p>
<p>1.4. Notices</p>
<p>All notices from Helio intended for receipt by You shall be deemed delivered and effective when sent to the email address provided by You during the registration process or as otherwise provided in these Terms or our Privacy Policy (https://joinhelio.com/privacy)(in either case, “Notice”). If You change your email address, you must update Your account information.</p>
<p>1.5 Keeping your Information Secure</p>
<p>You need to provide Helio with a valid, working e-mail address to access and use the Services. When you register, you will create a password for your account. Your e-mail address and password and any codes assigned to you are your “User Information.” When you create an account with us, you guarantee that the information you provide is accurate, complete, and current.</p>
<p>You MUST:</p>
<p>(A) Keep your User Information private,</p>
<p>(B) Do not allow another person to use your User Information to access the Services, and</p>
<p>(C) Do not allow another person to use your Device(s).</p>
<p>To protect your conﬁdential healthcare information, it is good practice to enable touch ID, ﬁngerprint ID, Face ID, and/or a passcode on your smartphone.</p>
<p>If you do not do the above and Helio suffers damages as a result, you will be responsible for all of those damages. You agree to immediately notify Helio in writing by email of any unauthorized use of your User Information or any other breach of security.</p>
<p>All of your communications using the Services can and will be monitored, captured, recorded, and transmitted to government authorities if we decide it is necessary, and we do not have to notify you.</p>
<p>2.0 MODIFICATIONS TO THE APPLICATION / TERMS</p>
<p>2.1. We Have the Right to Change These Terms</p>
<p>We may, at any time, modify, discontinue or terminate the Services or modify these Terms, without prior notice to you. If we modify these Terms, we will post the changes on our website or Application. If you continue to use the Services after we have let you know about the changes, you agree to be bound by the modified Terms. If the changes are not acceptable to you, you should immediately stop using the Services.</p>
<p>Some features of the Services may be subject to different terms and conditions. If you use those features, you agree to be bound by the additional terms of use applicable to such features. If any such additional terms conflict with these Terms, the additional terms will govern with respect to such features.</p>
<p>3.0 YOUR USE OF THE SERVICES</p>
<p>4.1.Generally</p>
<p>You shall use the Application and Website in strict compliance with (1) these Terms; (2) any additional applicable instructions, guidelines or policies issued by Helio, including those posted within the Application or on the Website; and (3) all applicable laws, rules and regulations (collectively, “Laws”).</p>
<p>4.2 Use Prohibitions</p>
<p>You agree to use the Website and Application only for their intended purpose. You must use the Website and Application in compliance with all privacy, data protection, intellectual property, and other applicable laws.</p>
<p>While using the Services, you shall not:</p>
<p>(A) Post, upload, publish, submit, transmit or otherwise make available any content that you do not have a right to make available;</p>
<p>(B) Use, display, mirror or frame the Services, or any individual element within the Services, Helio or Helio's name, any Helio trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Helio's express written consent;</p>
<p>(C) Access, tamper with, or use non-public areas of the Services, Helio's computer systems, or the technical delivery systems of Helio's service providers;</p>
<p>(D) Attempt to probe, scan, or test the vulnerability of any Helio system or network or breach any security or authentication measures;</p>
<p>(E) Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Helio or any third party to protect the Services;</p>
<p>(F) Attempt to access or search the Services or download materials from the Services through the use of any engine, software, tool, agent, device, or mechanism (including scripts, bots, spiders, scrapers, crawlers, data mining tools or the like) other than the software and/or search agents provided by Helio or other generally available third party web browsers;</p>
<p>(G) Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;</p>
<p>(H) Use any meta tags or other hidden text or metadata utilizing an Helio trademark, logo, URL, or product name without Helio’s express written consent;</p>
<p>(I) Use the Services or materials for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms;</p>
<p>(J) Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services or materials to send altered, deceptive or false source-identifying information;</p>
<p>(K) Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services or materials;</p>
<p>(L) Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</p>
<p>(M) Collect or store any personally identifiable information (not your own) from the Services;</p>
<p>(N) Impersonate or misrepresent your affiliation with any person or entity;</p>
<p>(O) Violate any applicable law or regulation; or</p>
<p>(P) Encourage or enable any other individual to do any of the above.</p>
<p>We may investigate and prosecute violations of any of the above to the fullest extent of the law. We may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms. Other than monitoring your healthcare vitals, we have no obligation to monitor your access to the Services, however, we may do so for the purpose of operating the Services to ensure your compliance with these Terms and the Privacy Policy, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. Helio reserves the right to, at any time and without prior notice, remove or disable access to any of its materials that We decide is in violation of these Terms, the Privacy Policy, or otherwise harmful to the Services.</p>
<p>4.3 Computer Equipment; Browser Access and Internet Services</p>
<p>With the exception of the Devices provided to you, you are responsible for obtaining, installing, maintaining and operating all software, hardware or other equipment (collectively, “Systems”) necessary for you to access and use the Services. This includes, without limitation, obtaining internet services, using up to date web-browsers and the best commercially available encryption, antivirus, anti-spyware, and internet security software. There are always certain security and access availability risks associated with using open networks such as the Internet, and you expressly assume such risks. You are responsible for the data security of the Systems used to access the Services and for the transmission and receipt of information using such Systems. We are not responsible for any errors or problems that arise from the malfunction or failure of the Internet or your System.</p>
<p>5.0 INTELLECTUAL PROPERTY RIGHTS; RESTRICTIONS ON USE</p>
<p>5.1. Ownership of the Services and Related Data</p>
<p>The Services and all materials on the Services are owned or licensed by Helio. We grant to you, for your personal purposes only, a nonexclusive, limited, and revocable right to access and use the Services during the term of this agreement, so long as you comply with these Terms. You agree not to use the Services for any other purpose, including commercial purposes, such as co-branding, framing, linking, or reselling any portion of the Services without our prior written consent.</p>
<p>You may access, download or print the materials available through the Services for non-commercial purposes and solely within the scope allowable by these Terms. You may not use the materials for any other purpose without our express written permission. Any unauthorized use of words or images from the Services may violate copyright laws, trademark laws, laws of privacy and publicity, and civil and criminal statutes</p>
<p>You may not use Helio’s name, trademarks, service marks or logos or those of third parties appearing on the Services in any advertising or publicity, or otherwise to indicate Helio’s or such third party’s sponsorship of or afﬁliation with any product or service without express written permission of Helio or such third party.</p>
<p>You own your Personal Information and any other content that you post on or through the Services. For Us to provide you with the Services, you grant to Helio a perpetual, non-exclusive, fully paid and royalty-free, transferable, sublicensable, worldwide license to use your content solely for the purpose of providing the Services. You also agree to allow Helio to de-identify and anonymize your content, including without limitation, your personal health information, and to use or disclose such de-identified information for purposes of Helio’s management, operations, or administration of its business and the Services, as further described in the Privacy Policy (https://joinhelio.com/privacy-policy).</p>
<p>5.2 User Content & Copyright Policy</p>
<p>By creating, posting, or sharing data, sound, and images on or through the Website or Application (“Your User Content”), and subject to the Privacy Notice, you grant Helio a perpetual, irrevocable, worldwide, non-exclusive, sub-licensable, royalty-free, fully paid up, transferable license to reproduce, distribute, publicly display, publicly perform, create derivative works of, and otherwise use and modify Your User Content for the purposes of providing and enhancing the Website, Application, or other Helio products and services. We may also create anonymized data and images from Your User Content, and such data and images will no longer be Your User Content. You waive any rights you may have regarding Your User Content being altered or manipulated in any way that may be objectionable to you. This license will terminate after you stop using the Website and Application. Helio reserves the right to refuse to accept, post, display, or transmit any of Your User Content in its sole discretion.</p>
<p>You represent and warrant that: (i) you own the content posted by you on or through the Website or Application or otherwise have the right to grant the license set forth in these Terms, (ii) the posting and use of Your User Content on or through the Website or Application does not violate the privacy rights, publicity rights, copyrights, contract rights, intellectual property rights, or any other rights of any person, and (iii) the postingof Your User Content on the Website or Application does not result in a breach of contract between you and a third party. You agree to pay for all royalties, fees, and any other monies owing any person by reason of content you post on or through the Website or Application. You also acknowledge and agree that Your User Content is non-confidential and non-proprietary.</p>
<p>Helio may review and remove Your User Content at any time for any reason, including for activity which, in its sole judgment: violates these Terms; violates applicable laws, rules, or regulations; is abusive, disruptive, offensive or illegal; or violates the rights of, or harms or threatens the safety of, users of the Website or Application.</p><p>Copyright Policy</p>
<p>Helio respects copyright law and expects its users to do the same. It is Helio’s policy to terminate, in appropriate circumstances, Users or other account holders who are believed to have infringed the rights of copyright holders.</p>
<p>Digital Millennium Copyright Act (DMCA) Notifications for intellectual property infringement.</p>
<p>If you believe that your work is the subject of copyright infringement and/or trademark infringement and appears in our Services, please provide Helio’s designated agent (listed below) the following information:</p>
<p>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
<p>Identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site.</p>
<p>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled via the Application, and information reasonably sufficient to permit Helio to locate such material.</p>
<p>Information reasonably sufficient to permit Helio to contact you as the complaining party, such as an address, telephone number, and, if available, an email address at which you may be contacted.</p>
<p>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright and/or trademark owner, its agent, or the law.</p>
<p>A statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
<p>Helio’s agent for notice of claims of copyright or trademark infringement on this App can be reached at:</p>
<p>Helio Logistics, Inc.</p>
<p>Attn: Helio DMCA Agent</p>
<p>272 W Park Avenue</p>
<p>Long Beach, NY 11561</p>
<p>Email: contact@joinhelio.com</p>
<p>Please also note that for copyright infringements under Section 512(f) of the Copyright Act, any person who knowingly materially misrepresents that material or activity is infringing may be subject to criminal prosecution for perjury and civil penalties, including monetary damages, court costs and attorneys’ fees.</p>
<p>Submitting a DMCA Counter-Notification</p>
<p>We will notify you that we have removed or disabled access to copyright-protected material that you provided, if such removal is pursuant to a valid DMCA take-down notice that we have received. If you receive such notice from us, you may provide us with a counter-notification in writing to Helio designated agent that includes all of the following information:</p>
<p>Your physical or electronic signature;</p>
<p>Identification of the material that has been removed or to which access has been disabled, and the location at which the material appeared before it was removed or access to it was disabled;</p>
<p>A statement from you under the penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and</p>
<p>Your name, physical address and telephone number, and a statement that you consent to the jurisdiction of a court for the judicial district in which your physical address is located, or if your physical address is outside of the United States, for any judicial district in which Helio may be located, and that you will accept service of process from the person who provided notification of allegedly infringing material or an agent of such person.</p>
<p>Termination of Repeat Infringers</p>
<p>Helio reserves the right, in its sole discretion, to terminate the account or access of any User of our Application who is the subject of repeated DMCA or other infringement notifications.</p>
<p>Please note that this procedure is exclusively for notifying Helio and its affiliates that your copyrighted material has been infringed. The preceding requirements are intended to comply with Helio’s rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact an attorney regarding your rights and obligations under the DMCA and other applicable laws.</p>
<p>We may give notice of a claim of copyright infringement to our users by means of a general notice on our Website, electronic mail to a User’s email address in our records, or by written communication sent by first-class mail to a User’s address in our records, as determined in our sole discretion. In accordance with the DMCA and other applicable law, Helio has adopted a policy of terminating, in appropriate circumstances and at Helio’s sole discretion, members who are deemed to be repeat infringers. Helio may also at its sole discretion limit access to the Website and Services and/or terminate the accounts of any Users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
<p>5.3. The Helio Findings and Reports</p>
<p>You may use the Helio Application to generate reports of findings using the heart or body sound data. Any reports provided by Helio do not suggest a diagnosis. The interpreted report is intended as information for You and to be used as a tool to provide proper diagnosis and treatment taking into account the patient’s complete medical history. Analysis and diagnosis based on the data can only be accomplished by a physician.</p>
<p>5.4. Regional Telemedicine Restrictions</p>
<p>Due to telemedicine restrictions, Your location may restrict Your ability to use the Application for telehealth purposes. Since you are using a mobile device to collect Your data, it is your responsibility to ensure the Application is legal according to Your local telemedicine laws. This section applies only to Helio telemedicine purposes and does not apply to Remote Patient Monitoring or other functions of the Services that are not restricted by current telemedicine laws and regulations.</p>
<p>You have the option to obtain a data recording from the Helio device at any time. Your heart and body sound recordings are subject to multiple factors related to your health and activities. Helio makes no guarantees of the accuracy or clinical significance of the interpretation of the data. You explicitly consent to sharing data with the third party electronic health record systems (EHRs) according to terms described in the Helio Privacy Notice located at https://joinhelio.com/privacy.</p>
<p>5.5 Feedback.</p>
<p>We welcome and encourage you to provide us with feedback, comments and suggestions for improvements to the Services, Devices or materials (“Feedback”). You may submit Feedback by emailing us at contact@joinhelio.com. If you submit any Feedback to us, we will own all intellectual property rights in such Feedback and may use such Feedback for any lawful purpose.</p>
<p>6.0 PRIVACY NOTICE</p>
<p>6.1. Privacy Notice</p>
<p>Helio respects the information you provide to us. Please see our Privacy Policy (https://joinhelio.com/privacy) for an explanation of how we collect and handle your personal information that is not subject to HIPAA, the Health Insurance Portability and Accountability Act, which is the primary federal law governing health privacy. By clicking on the “I Agree” or “I Accept” button, accessing or using the Services, or by downloading or uploading any content from or through the Services, you acknowledge and agree to the provisions of the Privacy Policy and affirm that the Privacy Policy is a part of these Terms.</p>
<p>By using the Services and accepting these Terms, you acknowledge that Helio will share your Personal Information collected by the Services with your Provider, and may share it with other third parties for those purposes described in the Privacy Policy.</p>
<p>We are not responsible for nor liable to you or any third party for a healthcare provider’s treatment of Personal Information, including any collection, use, disclosure, storage, loss, theft or misuse of your Personal Information, whether or not such treatment violates applicable law or the Provider’s Notice of Privacy Practices.</p>
<p>7.0 SUSPENSION AND TERMINATION</p>
<p>7.1 Suspension and Termination</p>
<p>If you breach any of these Terms, we may suspend or disable your account or terminate your access to the Services, without prior notice to you. There may be other instances where We may need to terminate your access to the Services that are not related to any of your actions or inactions. We reserve the right to terminate your access to and use of the Services and materials at any time, with or without cause.</p>
<p>‍</p>
<p>7.4. Effect of Termination</p>
<p>If We or You terminate your access to the Services: (i) You will no longer be authorized to access or use the Application or Website or otherwise use any of the features or services offered by or through the Application or Website; and (ii) Helio may delete any data associated with You or Your account.</p>
<p>8.0 REPRESENTATIONS, COVENANTS AND WARRANTIES</p>
<p>8.1. Warranty Disclaimers</p>
<p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE WEBSITE AND APPLICATION IS AT YOUR SOLE RISK, AND THE WEBSITE AND APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS OR EXPRESSLY STATED ELSEWHERE BY Helio, Helio MAKES NO REPRESENTATIONS, COVENANTS OR WARRANTIES AND OFFERS NO OTHER CONDITIONS, EXPRESS OR IMPLIED, REGARDING ANY MATTER, INCLUDING (1) THE MERCHANTABILITY, SUITABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, NON-INFRINGEMENT OR RESULTS TO BE DERIVED FROM THE USE OF THE APPLICATION, WEBSITE OR ANY DATA SERVICE, SOFTWARE, HARDWARE, DELIVERABLE, WORK PRODUCT OR OTHER MATERIALS RELATED TO THE APPLICATION OR WEBSITE, OR THE AVAILABILITY OF ANY OF THE FOREGOING; OR (2) WHETHER THE INFORMATION AVAILABLE ON OR TRANSMITTED BY THE APPLICATION OR WEBSITE IS TRUE, COMPLETE OR ACCURATE. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT Helio IS NOT RESPONSIBLE FOR ANY HEALTHCARE OR RELATED DECISIONS MADE BY YOU OR YOUR HEALTHCARE PROFESSIONAL BASED UPON DATA COLLECTED, TRANSMITTED OR DISPLAYED BY OR ON THE APPLICATION OR WEBSITE, WHETHER SUCH DATA IS ACCURATE OR INACCURATE. FURTHER, Helio DOES NOT REPRESENT, COVENANT OR WARRANT THAT ACCESS TO OR SERVICES PROVIDED BY THE APPLICATION OR WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ACKNOWLEDGE AND AGREE THAT THERE ARE RISKS INHERENT TO TRANSMITTING INFORMATION OVER AND STORING INFORMATION ON THE INTERNET AND THAT Helio IS NOT RESPONSIBLE FOR ANY LOSSES OF YOUR DATA, CONFIDENTIALITY OR PRIVACY IN CONNECTION THEREWITH.</p>
<p>9.0 LIABILITY</p>
<p>9.1. Limitation of Liability</p>
<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER Helio NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR MATERIALS SHALL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RESULTING FROM YOUR USE OF THE APPLICATION OR WEBSITE, OR BASED UPON ANY BREACH OF ANY EXPRESS OR IMPLIED WARRANTY, BREACH OF CONTRACT (INCLUDING THESE TERMS AND ANY ADDITIONAL INSTRUCTIONS, GUIDELINES OR POLICIES ISSUED BY Helio, INCLUDING THOSE POSTED IN THE APPLICATION OR ON THE WEBSITE), NEGLIGENCE, TORT OR ANY OTHER LEGAL THEORY (COLLECTIVELY, THE “EXCLUDED DAMAGES”). FOR THE AVOIDANCE OF DOUBT, THE EXCLUDED DAMAGES ALSO INCLUDE WITHOUT LIMITATION, LOSS OF SAVINGS OR REVENUE; LOSS OF PROFIT; LOSS OF USE; LOSS OF LIFE OR HEALTH, THE CLAIMS OF THIRD PARTIES; AND ANY COST OF ANY SUBSTITUTE EQUIPMENT OR SERVICES.</p>
<p>IF YOU ARE NOT SATISFIED WITH THE SERVICES, THE MATERIALS, OR THE TERMS, YOU SHOULD DISCONTINUE USING THEM – THIS IS YOUR ONLY REMEDY. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THESE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH STATES, Helio’S LIABILITY IS LIMITED AND WARRANTIES ARE EXCLUDED TO THE GREATEST EXTENT PERMITTED BY LAW, BUT SHALL, IN NO EVENT, EXCEED $100.00. YOU MUST BRING ANY CLAIM ARISING FROM THE USAGE OF THE SERVICES OR MATERIALS WITHIN ONE (1) YEAR OF THE EVENT FROM WHICH THE CLAIM AROSE.</p>
<p>9.2. Remedies</p>
<p>At its option, Helio may seek all remedies available to it under law and in equity, including injunctive relief in the form of specific performance to enforce these Terms, including any additional instructions, guidelines or policies issued by Helio, including those posted in the Application or on the Website, and/or actions for damages.</p>
<p>10.0 INDEMNITIES</p>
<p>10.1. Indemnity by You</p>
<p>You agree to indemnify, defend and hold harmless Helio, its clients, and its suppliers and their respective afﬁliates, employees, ofﬁcers, directors, agents, servants and representatives of each from any liability, loss, claim, suit, damage, and expense (including reasonable attorneys’ fees and expenses) arising out of or in any way connected with your access to or use of the Services or Helio’s materials, your violation of these Terms, or any negligent or wrongful conduct by you or related to your account by you or any other person accessing the Services or Helio materials through your account.</p>
<p>11.0 DISPUTE RESOLUTION</p>
<p>11.1. Disputes</p>
<p>If you or Helio has any dispute regarding these Terms, including but not limited to any alleged breach of these Terms, the parties will submit the dispute to binding arbitration in New York before a single arbitrator, in accordance with rules and procedures of the American Arbitration Association. The arbitrator may, but does not have to, award legal fees, arbitrator’s fees and costs and other costs incurred by the party that does not win the dispute. Any arbitration will be strictly confidential and neither party will disclose to any person (other than necessary to carry out the arbitration) the existence of the dispute or any aspect of the dispute.</p>
<p>11.2. Governing Law</p>
<p>To the fullest extent permitted pursuant to applicable law, these Terms are governed by the laws of the State of New York without regard to conflict of law principles. If a lawsuit or court proceeding is permitted under these Terms, then you and Helio agree to submit to the personal and exclusive jurisdiction of the state courts and federal courts located within Westchester County, New York for the purpose of litigating any dispute. If you are a consumer located in the EU, such jurisdiction of the Westchester County courts will be non-exclusive.</p>
<p>12.0 GENERAL</p>
<p>12.1. General Legal Provisions</p>
<p>If any provision of this Agreement is determined to be invalid, illegal or unenforceable, the remaining provisions of the Agreement remain in full force, provided that the essential terms and conditions of this Agreement remain valid, binding and enforceable and the economic and legal substance of the transactions contemplated by the Agreement are materially preserved.</p>
<p>The United States export control laws regulate the export and re-export of technology originating in the United States. This includes the electronic transmission of information and software to foreign countries and to certain foreign nationals. You agree to abide by these laws and their regulations.</p>
<p>Nothing in this agreement creates an agency, partnership, or joint venture. Failure to enforce any provision will not constitute a waiver of that provision.</p>
<p>12.2 Assignment</p>
<p>You may not assign these Terms (or any rights, benefits or obligations hereunder) by operation of law or otherwise without the prior written consent of Helio, which may be withheld at Helio’s sole discretion. Any attempted assignment by You that does not comply with the terms of this Section shall be null and void. Helio may assign these Terms and Conditions, in whole or in part, to any third party in its sole discretion.</p>
<p>12.3. Contact</p>
<p>The Service is offered by Helio Logistics, Inc. You may contact us by emailing us at contact@join Helio.com.</p>
<p>Helio Logistics, Inc.</p>
<p>272 W Park Avenue</p>
<p>Long Beach, NY 11561</p>
<p>‍</p>
<p>POL003 Rev A.0</p>
<p>COVID -19 TESTING AUTHORIZATION AND CONSENT</p>
<p>Please do not use our services until you have first read this COVID-19 Testing Authorization and Consent and subsequently made an informed decision that our services are right for you.</p>
<p>BACKGROUND ON OUR SERVICES: Helio Logistics, Inc. d/b/a Next Medical, together with our affiliated laboratories, is pleased to facilitate COVID-19 testing. This includes the performance of testing by various means (including nasal swab specimens) by individuals who have opted-in for COVID-19 testing (hereinafter “COVID-19 testing”). Following processing of tested specimens, we will notify you of COVID-19 testing results.</p>
<p>CONSENT TO COVID-19 TESTING: You hereby agree to undergo COVID-19 testing in accordance with the instructions provided to you, including cooperation with all healthcare professionals and personnel to collect an appropriate specimen safely and effectively. You agree to comply with all instructions provided to you related to administration of the COVID-19 testing kit. You further acknowledge that the COVID-19 testing kit is available as a result of the U.S. Food and Drug Administration’s Emergency Use Authorization (“EUA”) process under section 564 of the Federal Food, Drug, and Cosmetic Act. EUAs make available diagnostic and therapeutic medical devices to diagnose and respond to public health emergencies by allowing unapproved medical products or unapproved uses of approved medical products to be used in an emergency to diagnose, treat, or prevent serious or life-threatening diseases or conditions caused by chemical, biological, radiological and nuclear threat agents when there are no adequate, approved, and available alternatives. As a result, the COVID-19 testing kit is subject to certain limitations. You understand that as with any type of medical or health related test, procedure or treatment, certain risks apply. COVID-19 testing risks include the risk of injury as the result of administering the test; the risk of improper administration; and inaccurate test results.</p>
<p>In addition to the foregoing you acknowledge the following:</p>
<p>RISKS OF DISCOMFORT: Testing may involve discomfort, including pain, tearing up, and/or triggering a gag reflux.</p>
<p>RISK OF INACCURACY: There is a risk the test will result in a false positive or false negative result, and a positive or negative test result does not mean there are no additional possible adverse health conditions or outcomes I may experience.</p>
<p>RISK OF EXPOSURE: Being present in the same space as others, despite my own efforts and those of the health professionals working with me, may increase the risk of my exposure to COVID-19 and the novel coronavirus (SARS-CoV-2). Even following best practices, it is possible for me and Provider personnel to be unaware that we are contagious even without symptoms, raising the possibility of infection. I am aware that exposure to COVID-19 can result in severe illness, intensive therapies, extended intubation and/or ventilator support, life-altering changes to my health, and even death.</p>
<p>RISK OF DISCLOSURE: The U.S. Centers for Disease Control and Prevention requires the Provider and the laboratory processing my specimen to report my test results, whether positive or negative, to my local public health authority. In addition to the test results, Next Medical will report certain personal information, not limited to, my age, sex, ethnicity, and zip code.</p>
<p>SEEK OTHER SOURCES OF CARE FOR OTHER HEALTH NEEDS: Please note that Next Medical does not take direct responsibility for your health or care beyond facilitating needed testing. Our services are limited to COVID-19 testing. The physicians who order tests are not your doctors for any other purposes. You need to seek other sources of care for your healthcare needs, including to examine any other health issues you may experience and to treat you for COVID-19 or any other conditions you have.</p>
<p>TESTING LIMITATIONS: I understand the Test is available as a result of the FDA’s Emergency Use Authorization (“EUA”) under Section 564 of the Federal Food, Drug, and Cosmetic Act. The EUA’s make available diagnostic tests to diagnose and respond to public health emergencies by allowing unapproved medical products or unapproved uses of approved medical products to be used in an emergency to diagnose, treat, or prevent serious or life-threatening diseases or conditions caused by chemical, biological, radiological and nuclear threat agents when there are no adequate, approved, and available alternatives. As a result, Testing may be subject to certain limitations as set forth in this Informed Consent.</p>
<p>NOT FOR EMERGENCIES: Next Medical does not provide healthcare on an emergency basis anywhere at any time and is not a substitute for your physician. Please do not delay seeking care from in a medical emergency or in place of your doctor. In an emergency, dial 911 or go to a hospital emergency department.</p>
<p>AGREEMENT TO ANSWER THE ONLINE QUESTIONAIRE TRUTHFULLY AND USE SERVICES HONESTLY: You accept the responsibility to provide full and truthful answers to all questions and, when requested, to provide all other data in the most accurate form possible.</p>
<p>If you do not understand anything in this Consent, do not proceed. If you go forward with the COVID-19 testing, we will assume that you understood and were able to discuss your questions and concerns to your satisfaction.</p>
<p>COVID-19 INFORMED CONSENT: By clicking that I have read and agree to this informed consent, I hereby acknowledge that I have been advised of the above risks, benefits, and alternatives identified below with respect to COVID-19 testing and the current pandemic-related changes to treatment and care. I have had the opportunity to discuss the risks identified below, to questions, and receive answers to my satisfaction. By signing below, I hereby authorize and direct the provider to administer COVID-19 testing.</p>
<p>I acknowledge that my insurance may be used for lab processing.</p>
<p>RT-PCR Refund Eligibility: If results are late by 6 hours or fewer, you are eligible for a $50 refund. If results are between 6 and 12 hours late, you are eligible for a $100 refund. If results are more than 12 hours late, you are eligible for a full refund.</p>
<p>I hereby hold harmless, release, and forever discharge Next Medical’s and all health professionals involved in my testing from all claims, demands, and causes of action that I, my heirs, representatives, executors, administrators, or any other persons acting on my behalf or on behalf of my estate have or may have by reason of any problems associated with COVID-19 testing.</p>
<p>DO NOT DIGITALLY CONSENT TO THIS FORM UNLESS YOU HAVE READ IT AND UNDERSTAND IT. ASK ANY QUESTIONS YOU HAVE BEFORE ACKNOWLEDGING CONSENT.</p>
<p>Based on the above, I certify that I have read the foregoing Informed Consent, had opportunities to ask questions, agree and accept all of the terms above, and voluntarily consent as noted above.</p>
<p>Assignment of Benefits</p>
<p>Partner Laboratories: I hereby authorize Next Medical partner laboratories such as, Empire City Labs, its subsidiary, and affiliated receiving and/or performing laboratories (collectively herein, "Laboratory") and/or its authorized agents, to run the specified tests on my blood and other specimens or samples. I understand that as a courtesy, Laboratory and/or its authorized agents will make every reasonable effort to obtain insurance reimbursement for ordered tests. I understand that I am making an assignment of my insurance plan benefits to Laboratory and/or its authorized agents. I also authorize the release of any information contained in my records that is needed to file and process insurance or medical plan claims, to pursue appeals on any denied or partially paid claims, for legal pursuit as to any unpaid or partially paid claims, or to pursue any other remedies necessary in connection with the same. Bill to my insurance: I understand that if my insurance company pays me directly for services rendered by Laboratory, I am responsible for forwarding such payment to Laboratory.</p>
<p>Physician Oversight: I, hereby authorize and direct, clinicians for Matthew Cohen, MD, (“Provider”), its subsidiary, and affiliated physicians and medical staff to administer and interpret a viral COVID-19 test or a serological test to detect COVID-19 antibodies.</p>
<p>I understand that as a courtesy, Provider and/or its authorized agents will make every reasonable effort to obtain insurance reimbursement for consultations and visits. I understand that I am making an assignment of my insurance plan benefits to Provider and/or its authorized agents. I also authorize the release of any information contained in my records that is needed to file and process insurance or medical plan claims, to pursue appeals on any denied or partially paid claims, for legal pursuit as to any unpaid or partially paid claims, or to pursue any other remedies necessary in connection with the same. Bill to my insurance: I understand that if my insurance company pays me directly for services rendered by Laboratory, I am responsible for forwarding such payment to Laboratory.</p>
                  </div>



                </div>
              </ScrollAnimation>
            </Col>
          </Row>
      </section>
    </>
  );
}

export default TermsConditions;
