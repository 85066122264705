import React from "react";

// reactstrap components
import {
  Button,
 
  NavLink,
  Row,
  Col,
  
} from "reactstrap";

import "../../aboutus.css"
import { GetPhone } from "../../GetPhone.js";
import { useMediaQuery } from 'react-responsive'

// Core Components

function Footer4() {

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200})
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 868, maxWidth: 1199 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 867 })
    return isMobile ? children : null
  }
  const [phone, setPhone] = React.useState("")

  React.useEffect(() => {
    GetPhone().then(data => {
      setPhone(data);
    })
  }, [])

 
  return (
    <>
      <footer className="footer footer-big" style={{fontFamily: "Montserrat"}}>
          <Desktop>
          <div className="content mx-7 px-7">
            <Row>
              <Col md="3" style={{backgroundColor: 'transparent'}}>
                <div className="column">
                  <img
                    style={{height: 40}}
                    alt="..."
                    className="logo"
                    src="http://d75d4vxnk04h5.cloudfront.net/logo.webp"
                  ></img>
                </div>

              </Col>
              {/* <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Company</h4>
                  <ul>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Careers
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Press
                      </a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Products</h4>
                  <ul>
                    
                    <li>
                      <a href="/sexual-health">
                        Sexual Health
                      </a>
                    </li>
                    <li>
                      <a href="/allergy">
                        Allergy
                      </a>
                    </li>
                    <li>
                      <a href="/womens_health">
                        Women's Health
                      </a>
                    </li>

                    <li>
                      <a href="/mens_health">
                        Men's Health
                      </a>
                    </li>
                    
                    <li>
                      <a href="/wellness">
                        Wellness
                      </a>
                    </li>

                    <li>
                      <a href="/covid">
                        COVID
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="2" xs="6">
                <div className="column">
                  <h4 className="mt-3">Contact</h4>
                  <ul>
                    <li>
                      <a href={phone} >
                        Call us
                      </a>
                    </li>
                    <li>
                      <a href="mailto:team@joinnextmed.com" >
                        Mail us
                      </a>
                    </li>
                    <li>
                        <div className="btn-wrapper text-left mt-3">
                        <Button
                          color="primary"
                          href="https://www.facebook.com/Next-Medical-104600488154309"
                          id="tooltip860625601"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-facebook"></i>
                        </Button>
                        <Button
                          color="primary"
                          href="https://instagram.com/next_medical"
                          id="tooltip576935159"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-instagram"></i>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4">
                <p style={{fontSize: 14}}>
                Next Medical-affiliated physician practices are independently owned and operated and staffed by licensed physicians who provide services utilizing the Next Medical telehealth platform. Doctors are not employed by Next Medical. They work for independent entities and are dedicated to protecting your health and privacy.
                </p>
              </Col>
            </Row>
            <hr></hr>

            
              <Row>
                <Col md="6">
                  <div className="column">
                    <nav>
                      <ul>
                        {/* <li className="d-inline-block">
                          <NavLink
                            href="https://www.creative-tim.com?ref=adspr-footer4"
                            target="_blank"
                          >
                            Contact Us
                          </NavLink>
                        </li> */}
                        <li className="d-inline-block">
                          <NavLink
                            href="/terms"
                            target="_blank"
                          >
                            Terms of Service
                          </NavLink>
                        </li>
                        <li className="d-inline-block">
                          <NavLink
                            href="/privacy"
                            target="_blank"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
                <Col md="6" style={{textAlign: 'right'}}>
                  <p>© 2021 Next Medical. All Rights Reserved.</p>
                </Col>
              </Row>
           


          </div>
          </Desktop>
          <Tablet>
          <div className="content mx-4">
            <Row>
              <Col md="3" style={{backgroundColor: 'transparent'}}>
                <div className="column">
                  <img
                    style={{height: 40}}
                    alt="..."
                    className="logo"
                    src="http://d75d4vxnk04h5.cloudfront.net/logo.webp"
                  ></img>
                </div>

              </Col>
              {/* <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Company</h4>
                  <ul>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Careers
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Press
                      </a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Products</h4>
                  <ul>
                    <li>
                      <a href="/sexual-health">
                        Sexual Health
                      </a>
                    </li>
                    <li>
                      <a href="/allergy">
                        Allergy
                      </a>
                    </li>
                    <li>
                      <a href="/womens_health">
                        Women's Health
                      </a>
                    </li>

                    <li>
                      <a href="/mens_health">
                        Men's Health
                      </a>
                    </li>
                    
                    <li>
                      <a href="/wellness">
                        Wellness
                      </a>
                    </li>

                    <li>
                      <a href="/covid">
                        COVID
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="2" xs="6">
                <div className="column">
                  <h4 className="mt-3">Contact</h4>
                  <ul>
                    <li>
                      <a href="tel:+1-212-530-7870" >
                        Call us
                      </a>
                    </li>
                    <li>
                      <a href="mailto:team@joinnextmed.com" >
                        Mail us
                      </a>
                    </li>
                    <li>
                        <div className="btn-wrapper text-left mt-3">
                        <Button
                          color="primary"
                          href="https://www.facebook.com/joinnextmed"
                          id="tooltip860625601"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-facebook"></i>
                        </Button>
                        <Button
                          color="primary"
                          href="https://instagram.com/next_medical"
                          id="tooltip576935159"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-instagram"></i>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4">
                <p style={{fontSize: 14}}>
                Next Medical-affiliated physician practices are independently owned and operated and staffed by licensed physicians who provide services utilizing the Next Medical telehealth platform. Doctors are not employed by Next Medical. They work for independent entities and are dedicated to protecting your health and privacy.
                </p>
              </Col>
            </Row>
            <hr></hr>

              <Row>
                <Col md="12">
                  <div className="column">
                    <nav>
                      <ul>
                        {/* <li className="d-inline-block">
                          <NavLink
                            href="https://www.creative-tim.com?ref=adspr-footer4"
                            target="_blank"
                          >
                            Contact Us
                          </NavLink>
                        </li> */}
                        <li className="d-inline-block">
                          <NavLink
                            href="https://www.joinnextmed.com/terms"
                            target="_blank"
                          >
                            Terms of Service
                          </NavLink>
                        </li>
                        <li className="d-inline-block">
                          <NavLink
                            href="https://www.joinnextmed.com/privacy"
                            target="_blank"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
                <Col md="12" className="mt-4" style={{textAlign: 'center'}}>
                  <p>© 2021 Next Medical. All Rights Reserved.</p>
                </Col>
              </Row>
            


          </div>
          </Tablet>
          <Mobile>
          <div className="content px-3">
            <Row>
              <Col md="4" style={{backgroundColor: 'transparent'}}>
                <div className="column">
                  <img
                    style={{height: 40}}
                    alt="..."
                    className="logo"
                    src="http://d75d4vxnk04h5.cloudfront.net/logo.webp"
                  ></img>
                </div>

              </Col>
              {/* <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Company</h4>
                  <ul>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Careers
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Press
                      </a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md="4" xs="6">
                <div className="column mt-5">
                  <h4 className="mt-3">Products</h4>
                  <ul>
                    <li>
                      <a href="/sexual-health">
                        Sexual Health
                      </a>
                    </li>
                    <li>
                      <a href="/allergy">
                        Allergy
                      </a>
                    </li>
                    <li>
                      <a href="/womens_health">
                        Women's Health
                      </a>
                    </li>

                    <li>
                      <a href="/mens_health">
                        Men's Health
                      </a>
                    </li>
                    
                    <li>
                      <a href="/wellness">
                        Wellness
                      </a>
                    </li>

                    <li>
                      <a href="/covid">
                        COVID
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4" xs="6">
                <div className="column mt-5">
                  <h4 className="mt-3">Contact</h4>
                  <ul>
                    <li>
                      <a href={phone} >
                        Call us
                      </a>
                    </li>
                    <li>
                      <a href="mailto:team@joinnextmed.com" >
                        Mail us
                      </a>
                    </li>
                    <li>
                        <div className="btn-wrapper text-left mt-3">
                        <Button
                          color="primary"
                          href="https://www.facebook.com/joinnextmed"
                          id="tooltip860625601"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-facebook"></i>
                        </Button>
                        <Button
                          color="primary"
                          href="https://instagram.com/next_medical"
                          id="tooltip576935159"
                          size="sm"
                          target="_blank"
                        >
                          <i style={{color: 'white'}} className="fab fa-instagram"></i>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4" className="mt-6">
                <p style={{fontSize: 14}}>
                  Next Medical-affiliated physician practices are independently owned and operated and staffed by licensed physicians who provide services utilizing the Next Medical telehealth platform. Doctors are not employed by Next Medical. They work for independent entities and are dedicated to protecting your health and privacy.
                </p>
              </Col>
            </Row>
            <hr></hr>

            
              <Row>
                <Col md="12">
                  <div className="column">
                    <nav>
                      <ul>
                        {/* <li className="d-inline-block">
                          <NavLink
                            href="https://www.creative-tim.com?ref=adspr-footer4"
                            target="_blank"
                          >
                            Contact Us
                          </NavLink>
                        </li> */}
                        <li className="d-inline-block">
                          <NavLink
                            href="https://www.joinnextmed.com/terms"
                            target="_blank"
                          >
                            Terms of Service
                          </NavLink>
                        </li>
                        <li className="d-inline-block">
                          <NavLink
                            href="https://www.joinnextmed.com/privacy"
                            target="_blank"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
                <Col md="12" className="mt-4" style={{textAlign: 'center'}}>
                  <p>© 2021 Next Medical. All Rights Reserved.</p>
                </Col>
              </Row>
            


          </div>
          </Mobile>
        
      </footer>
    </>
  );
}

export default Footer4;
