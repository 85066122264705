import React from "react";

// reactstrap components

// Core Components
import FinalNavbar from "components/navbars/FinalNavbar.js";

import "./aboutus.css"

import Footer4 from "components/footers/Footer4.js";
import TermsConditions from "components/blogs/TermsConditions.js";

function Terms() {

  return (
    <>
      <div style={{overflow: "hidden"}}>
        <FinalNavbar type="white" />
        <div className="wrapper">
          <TermsConditions />
          <Footer4 />
        </div>
      </div>
    </>
  );
}

export default Terms;
