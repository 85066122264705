import React, { lazy, Suspense } from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// Core Components

import base_url from "./base_url"
import "./aboutus.css"

const Results = lazy(() => import("./Results"));
const Visits = lazy(() => import("./Visits"));

const LoginBanner = lazy(() => import('./LoginBanner'));
const RegisterBanner = lazy(() => import('./RegisterBanner'));


class AboutUs extends React.Component {



  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      cart: [],
      totalPrice: 0,
      is_ny: false,
      scroll: 0,
    }
  }

  
  setIsOpen = (e) => {
    this.setState({isOpen: e});
  }

  
  render(){
    
    return (
      <Suspense fallback={null}>
    
 

    {/* {this.props.type === "chat" && ( */}
    {/*   <SendbirdChat setIsOpen={this.setIsOpen} isOpen={this.state.isOpen}/> */}
    {/*   )  */}
    {/* } */}

    {this.props.type !== "chat" && (
     <>
    

    <div style={{overflow: "hidden"}}>
      
        <Row>


          
            <Col lg={12}>

            {this.props.type === "login" && 
              <LoginBanner type={this.props.type} setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} addToCart={this.addToCart} />
            }
            {this.props.type === "register" && 
              <RegisterBanner type={this.props.type} setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} addToCart={this.addToCart} />
            }
            {this.props.type === "results" && 
              <Results mrn={this.props.match.params.mrn} type={this.props.type} setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} addToCart={this.addToCart}/>
            }

            

            { this.props.type === "visits" && 
              <Visits type={this.props.type} setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} addToCart={this.addToCart}/>
            }
            </Col>
                  
              
      </Row>
    </div>
    </>
    )}

     </Suspense> 
  );
  }

  
}

export default AboutUs;
