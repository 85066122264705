import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Pool from "../../views/examples/UserPool";

import "../../aboutus.css"
import { GetPhone } from "../../GetPhone.js";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
 
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Button,
  Row,
  Col,
 
  NavItem
} from "reactstrap";

const callback = function (){
  console.log("phone call");
}

function FinalNavbar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  
  const [phone, setPhone] = React.useState("")


  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();

      let temp_cart = window.sessionStorage.getItem('cart');
      let temp_price = window.sessionStorage.getItem('totalPrice');
      
      window.sessionStorage.clear()
      window.sessionStorage.setItem('cart', temp_cart);
      window.sessionStorage.setItem('totalPrice', temp_price)
    }
  };

  React.useEffect(() => {
    GetPhone().then(data => {
      setPhone(data);
    })
  }, [])


  let navbarType = "";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      break;
    case "white":
      navbarType = "bg-white";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }
  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        // id="dark-navbar-main"
        style={{
          position: "relative", 
          // boxShadow: "0 10px 10px -10px lightgrey", 
          paddingTop: 10, 
          paddingBottom: 10,
          borderBottom: "0.5px solid rgba(57, 158, 255, 0.2)"
        }}
      >
          <NavbarBrand className="ml-lg-4" >
              <a href="/"><img style={{height: 35}} alt="..." src="http://d75d4vxnk04h5.cloudfront.net/logo.webp"></img></a>
          </NavbarBrand>
         
          
          <Row >

            <button
              className="navbar-toggler"
              type="button"
              
            >
            
            <i  onClick={() => props.setIsOpen(true)} style={{cursor: "pointer", color: "#8898aa", fontSize: 23}} className="fa fa-shopping-cart mr-2"></i>
            </button>
            <button
              className="navbar-toggler"
              type="button"
              
            >
            
            <i onClick={() => {window.fbq('track', 'Contact'); window.gtag('event', 'conversion', {
              'send_to': 'AW-445749511/uvB9CLSA8fsBEIeyxtQB',
              'event_callback': callback
              }); window.location.href = phone}} style={{cursor: "pointer", color: "#8898aa", fontSize: 23}} className="fa fa-phone-alt mr-2"></i>
            </button>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => toggleCollapse(!collapseOpen)}
            >

              <span className="navbar-toggler-icon"></span>
            </button>
          </Row>
          
                
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  
                    
                    <img
                      alt="..."
                      src="http://d75d4vxnk04h5.cloudfront.net/logo.webp"
                    ></img>
                  
                  
                </Col>
                
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto"
              navbar
              
            >
              
              
                
              

              {/* <AnchorLink onClick={() => toggleCollapse(false)} onMouseEnter={() => setHover1(true)} onMouseLeave={() => setHover1(false)} href="#howitworks" style={hover1 ? {color: '#399EFF'} : {color: '#525f7f'}}>
                <NavItem>
                  <NavLink style={{fontFamily: "Montserrat"}}>
                    How It Works
                  </NavLink>
                </NavItem>
              </AnchorLink> */}

              {/* <AnchorLink href="#businesses" onClick={() => toggleCollapse(false)} onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)}  style={hover3 ? {color: '#399EFF'} : {color: '#525f7f'}}>
                <NavItem>
                  <NavLink style={{fontFamily: "Montserrat"}}>
                    Businesses
                  </NavLink>
                </NavItem>
              </AnchorLink> */}

              {props.is_results !== true && (<>
              {window.sessionStorage.getItem("isLoggedIn") === "true" ? (
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      tag={NavLink}
                      data-toggle="dropdown"
                      href="#pablo"
                      caret
                      onClick={(e) => e.preventDefault()}
                      role="button"
                    >
                      <span className="nav-link-inner--text" style={{fontFamily: "Montserrat", marginLeft: 1}}>Account</span>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="mt-xs--3">
                      <DropdownItem tag={Link} to="/visits" style={{fontFamily: "Montserrat"}}>
                        <i className="ni ni-bullet-list-67 text-primary"></i>
                        Your Visits
                      </DropdownItem>
                      {/* <DropdownItem tag={Link} to="/results" style={{fontFamily: "Montserrat"}}> */}
                      {/*   <i className="ni ni-single-copy-04 text-success"></i> */}
                      {/*   Test Results */}
                      {/* </DropdownItem> */}
                      {/* <DropdownItem tag={Link} to="/chat" style={{fontFamily: "Montserrat"}}> */}
                      {/*   <i className="fa fa-comment-medical text-primary"></i> */}
                      {/*   Message Your Doctor */}
                      {/* </DropdownItem> */}
                      <DropdownItem tag={Link} onClick={logout} style={{fontFamily: "Montserrat"}}>
                        <i className="ni ni-button-power text-danger"></i>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : 
                  <NavItem>
                    <NavLink style={{fontFamily: "Montserrat"}} href="/login">
                        Account
                    </NavLink>
                  </NavItem>
                }
                </>)}

                
                <NavLink style={{cursor: "pointer"}}>
                <Button onClick={() => {window.fbq('track', 'Contact'); window.gtag('event', 'conversion', {
              'send_to': 'AW-445749511/uvB9CLSA8fsBEIeyxtQB',
              'event_callback': callback
              }); window.location.href = phone}} style={{cursor: "pointer"}} color="primary" className="mr-2">Call Us</Button>
                </NavLink>
                
            </Nav>
            
          </Collapse>
      </Navbar>
    </>
  );
}

FinalNavbar.defaultProps = {
  type: "dark",
};

FinalNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default FinalNavbar;
