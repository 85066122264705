import React from "react";

// reactstrap components

// Core Components
import FinalNavbar from "components/navbars/FinalNavbar.js";

import "./aboutus.css"

import Footer4 from "components/footers/Footer4.js";
import PrivacyPolicy from "components/blogs/PrivacyPolicy";

function Privacy() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });


  return (
    <>
      <div style={{overflow: "hidden"}}>
        <FinalNavbar type="white" />
        <div className="wrapper">
          <PrivacyPolicy />
          <Footer4 />
        </div>
      </div>
    </>
  );
}

export default Privacy;
