import React, {useState, useEffect } from "react";
import {CognitoUser} from "amazon-cognito-identity-js";

import Pool from "./UserPool";
import {useHistory} from "react-router-dom";

import classnames from "classnames";
// reactstrap components

import "./aboutus.css"

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";



export default (props) => {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [confirm, setConfirm] = useState("");

    const [focusedEmail, setFocusedEmail] = useState(false);
    const [focusedCode, setFocusedCode] = useState(false);
    const [focusedPassword, setFocusedPassword] = useState(false);
    const [focusedConfirm, setFocusedConfirm] = useState(false);

    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [page, setPage] = useState(0);
    
    const [loading, setLoading] = useState(false);
    const getSession = async () =>
        await new Promise((resolve, reject) => {
          const user = Pool.getCurrentUser();

          if (user) {
            user.getSession(async (err, session) => {
              if (err) {
                reject();
              } else {
                const attributes = await new Promise((resolve, reject) => {
                  user.getUserAttributes((err, attributes) => {
                    if (err) {
                      reject(err);
                    } else {
                      const results = {};

                      for (let attribute of attributes) {
                        const {Name, Value} = attribute;
                        results[Name] = Value;
                      }

                      resolve(results);
                    }
                  });
                });

                resolve({
                  user,
                  ...session,
                  ...attributes,
                });
              }
            });
          } else {
            reject();
          }
        });

      const getUser = () => {
        return new CognitoUser({
          Username: email.toLowerCase(),
          Pool
        });
      };

      const sendCode = () => {
        
        setError("");
        setSuccess("");
        setLoading(true);
        

        getUser().forgotPassword({

          onSuccess: data => {
            setLoading(false);
            console.log("onSuccess:", data);
            setPage(1);
          },
          onFailure: err => {
            setLoading(false);
            console.error("onFailure:", err);
            setError(err.message);
          },
          inputVerificationCode: data => {
            setLoading(false)
            console.log("onSuccess:", data);
            setPage(1);
            
          }
        });
      };

      const resetPassword = () => {
        setError("");
        setSuccess("");
        setLoading(true);
        

        if (password !== confirm) {
          setLoading(false);
          setError("Passwords must match");
          console.log("passswords must matchhh")
          return;
        }

        getUser().confirmPassword(code, password, {
          
          onSuccess: data => {
            setLoading(false);
            
            setError("");
            setPage(2)
          },
          onFailure: err => {
            setLoading(false);
            setError(err.message)
            console.error("onFailure:", err);
           
          }
        });
      };

      
      

    

    useEffect(() => {
        getSession().then((results) => {
            console.log(results);
            setLoggedIn(true);
        });
    }, []);

    if (window.sessionStorage.getItem("isLoggedIn") === "true") {
        history.push("/visits");
    }

    
    const onSubmit = (event) => {
        

        // setLoading(true);
        event.preventDefault();
        
        sendCode()
        
        
            
    };

    const onSubmit1 = (event) => {
        event.preventDefault();
        
        resetPassword();
        
            
    };

    
    return (
         <>
        <Container fluid style={{backgroundColor: 'transparent', paddingTop: props.paddingTop}}>
          <Row className="justify-content-center">
            <Col xl={6} lg={8} md={8} sm={10} xs={10} style={{textAlign: 'left', fontFamily: "Montserrat"}}>
                  

                  {page === 0 && (
                    <>
                    <div className="text-muted mb-5" >
                    {props.fontClass === "display-4" && (
                      <h3 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Recover your Account</h3>
                    )}
                    {props.fontClass !== "display-4" && (
                      <h2 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Recover your Account</h2>
                    )}
                    
                    <p>Complete email verification to set a new password.</p>
                  </div>
                    <Form onSubmit={onSubmit}>
                    <Row>
                      <Col lg={12}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: focusedEmail
                          })}
                        >
                          <span style={{fontWeight: '600', color: "#525f7f", fontSize: '.875rem'}} >Email</span>
                          <InputGroup className="input-group-merge input-group-alternative mt-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="text"
                              onFocus={() => setFocusedEmail(true)}
                              onBlur={() => setFocusedEmail(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      
                    </Row>
                    {error !== "" && (
                      <p style = {{color: "red"}}>{error}</p>
                    )}
                    {success !== "" && (
                      <p style = {{color: "green"}}>{success}</p>
                    )}
                    


                    <Row className="mt-3 mb-4" >
                      <Col xs="6">
                        <a
                          className="text-dark"
                          href="/login"
                          
                        >
                          <small >Sign in to existing account</small>
                        </a>
                      </Col>
                      <Col className="text-right" xs="6">
                        <a
                          className="text-dark"
                          href="/register"
                        >
                          <small>Create new account</small>
                        </a>
                      </Col>
                    </Row>
                    
                    
                    
                    
                      <div className="text-center" >
                      <Button disabled={loading} type="submit" className="my-4" style={{width: '100%'}} color="primary" >
                        {loading? "Loading...": "Next"}
                      </Button>
                    </div>
                                        
                    
                  </Form>
                  </>
                  )}
                  {page === 1 && (
                    <>
                    <div className="text-muted mb-5" >
                    {props.fontClass === "display-4" && (
                      <h3 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Recover your Account</h3>
                    )}
                    {props.fontClass !== "display-4" && (
                      <h2 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Recover your Account</h2>
                    )}
                    <p>Complete email verification to set a new password.</p>
                  </div>
                    <Form onSubmit={onSubmit1}>
                    <Row>
                      <Col lg={12}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: focusedCode
                          })}
                        >
                          <span style={{fontWeight: '600', color: "#525f7f", fontSize: '.875rem'}} >Verification Code</span>
                          <InputGroup className="input-group-merge input-group-alternative mt-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-key-25" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Enter Code"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                              type="text"
                              onFocus={() => setFocusedCode(true)}
                              onBlur={() => setFocusedCode(false)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col lg={12} className="mt-2">
                        <FormGroup
                          className={classnames({
                            focused: focusedPassword
                          })}
                        >
                          <span style={{fontWeight: '600', color: "#525f7f", fontSize: '.875rem'}} >New Password</span>
                          <InputGroup className="input-group-merge input-group-alternative mt-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="New Password"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              onFocus={() =>
                                setFocusedPassword(true)
                              }
                              onBlur={() =>
                                setFocusedPassword(false)
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col lg={12} className="mt-2">
                        <FormGroup
                          className={classnames({
                            focused: focusedConfirm
                          })}
                        >
                          <span style={{fontWeight: '600', color: "#525f7f", fontSize: '.875rem'}} >Confirm Password</span>
                          <InputGroup className="input-group-merge input-group-alternative mt-2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              value={confirm}
                              onChange={(e) => setConfirm(e.target.value)}
                              onFocus={() =>
                                setFocusedConfirm(true)
                              }
                              onBlur={() =>
                                setFocusedConfirm(false)
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      
                    </Row>
                    {error !== "" && (
                      <p style = {{color: "red"}}>{error}</p>
                    )}
                    {success !== "" && (
                      <p style = {{color: "green"}}>{success}</p>
                    )}
                    


                    <Row className="mt-3 mb-4" >
                      <Col xs="6">
                        <a
                          className="text-dark"
                          href="/login"
                          
                        >
                          <small >Sign in to existing account</small>
                        </a>
                      </Col>
                      <Col className="text-right" xs="6">
                        <a
                          className="text-dark"
                          href="/register"
                        >
                          <small>Create new account</small>
                        </a>
                      </Col>
                    </Row>
                    
                    
                    {success !== "" && (
                      <div className="text-center" >
                      <Button href='/login' className="my-4" style={{width: '100%'}} color="primary" >
                        Go to Login
                      </Button>
                    </div>
                    )}
                    {success === "" && (
                      <div className="text-center" >
                      <Button disabled={loading} type="submit" className="my-4" style={{width: '100%'}} color="primary" >
                        {loading? "Loading...": "Confirm"}
                      </Button>
                    </div>
                    )}
                    
                    
                  </Form>
                  </>
                  )}
                  
                  {page === 2 && (
                    <>
                    <div className="text-muted mb-5" >
                      {props.fontClass === "display-4" && (
                      <h3 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Successfully reset password.</h3>
                      )}
                      {props.fontClass !== "display-4" && (
                        <h2 style={{fontFamily: "Montserrat", color: "#525f7f"}}>Successfully reset password.</h2>
                      )}
                      <p>Please login with your newly created credentials.</p>
                    </div>
                     
                   
                    
                    <div className="text-center" >
                      <Button href='/login' className="my-4" style={{width: '100%'}} color="primary" >
                        Go to Login
                      </Button>
                    </div>
                    </>
                    
                    
                    
                    
                 
                  )}
                  
              
            </Col>
          </Row>
        </Container>
      </>
    );
};