import React from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// Core Components
import FinalNavbar from "components/navbars/FinalNavbar.js";

import "./aboutus.css"
import 'animate.css';
import { useMediaQuery } from 'react-responsive'

import Footer4 from "components/footers/Footer4.js";

import ForgotPasswordForm from "./ForgotPasswordForm.js"

function ForgotPasswordBanner() {

  const ExtraLarge = ({ children }) => {
    const isExtraLarge = useMediaQuery({ minWidth: 1200 })
    return isExtraLarge ? children : null
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1199 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  return (
    <>
      <div style={{overflow: "hidden"}}>
        <FinalNavbar type="white" />
          <ExtraLarge>
            <Row style={{justifyContent: "center", backgroundColor: 'transparent', marginTop: 0, minHeight: "90vh"}}>
              {/* <Col lg={12}>
                <Banner margin={100}/>
              </Col> */}
              <Col style={{backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <ForgotPasswordForm paddingTop={0} fontClass="display-3" />
              </Col>
            </Row>
          </ExtraLarge>
          <Desktop>
            <Row style={{justifyContent: "center", backgroundColor: 'transparent', marginTop: 0, minHeight: "90vh"}}>
              {/* <Col lg={12}>
                <Banner margin={100}/>
              </Col> */}
              <Col style={{backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <ForgotPasswordForm paddingTop={0} fontClass="display-3"/>
              </Col>
            </Row>
          </Desktop>
          <Tablet>
            <Row style={{justifyContent: "center", backgroundColor: 'transparent', marginTop: 0, minHeight: "90vh"}}>
              {/* <Col lg={12}>
                <Banner margin={82}/>
              </Col> */}
              <Col style={{backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <ForgotPasswordForm paddingTop={0} fontClass="display-4"/>
              </Col>
            </Row>
          </Tablet>
          <Mobile>
            <Row style={{justifyContent: "center", backgroundColor: 'transparent', marginTop: 0, minHeight: "90vh"}}>
              {/* <Col lg={12}>
                <Banner margin={80}/>
              </Col> */}
              <Col style={{backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <ForgotPasswordForm paddingTop={0} fontClass="display-4"/>
              </Col>
            </Row>
          </Mobile>
        <Footer4 />
      </div>
      
    </>
  );
}

export default ForgotPasswordBanner;
