export function GetPhone () {
	
	const phones = {
		"NY": "tel:+1-212-530-7870",
		"IL": "tel:+1-312-776-2030",
		"FL": "tel:+1-305-363-4560",
		"CA": "tel:+1-310-594-7701",
		
	}

	return new Promise((resolve, reject) => {
		   fetch("https://api.ipdata.co/?api-key=b6d1025a9b9a1b06b379f4df05e29057872d43becac50d7e8ad7dc26").then(response => response.json()).then(result => {
				let final = "";
				if (result.region_code !== null && result.region_code !== undefined){
					final = phones[result.region_code];
				}
				else {
					final = phones["NY"];
				}

				if (final !== undefined && final !== null){
					resolve(final)
				}
				else {
					resolve(phones["NY"])
				}
				
			}).catch(err => {
				
				resolve(phones["NY"]);
				
			})
	});

	
}