import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import AboutUs from "views/examples/AboutUs.js";
// import Antibody from "views/examples/Antibody.js";
import Terms from "views/examples/Terms.js";
import Privacy from "views/examples/Privacy.js";
// import Unsubscribe from "views/examples/Unsubscribe.js";
// import CheckoutBanner from "views/examples/CheckoutBanner.js";
import ForgotPasswordBanner from "views/examples/ForgotPasswordBanner.js";
// import Track from "views/examples/Track.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/index" exact render={(props) => <Index {...props} />} />
      <Route
        path="/sections"
        exact
        render={(props) => <Sections {...props} />}
      />
      <Route
        path="/presentation"
        exact
        render={(props) => <Presentation {...props} />}
      /> */}

      

      <Route
        path="/terms"
        exact
        render={(props) => <Terms {...props} />}
      />

      <Route
        path="/privacy"
        exact
        render={(props) => <Privacy {...props} />}
      />

      <Route
        path="/login"
        exact
        render={(props) => <AboutUs type="login" {...props} />}
      />

      <Route
        path="/forgotpassword"
        exact
        render={(props) => <ForgotPasswordBanner {...props} />}
      />


      <Route
        path="/register"
        exact
        render={(props) => <AboutUs type="register" {...props} />}
      />

      <Route
        path="/results/:mrn"
        exact
        render={(props) => <AboutUs type="results" {...props} />}
      />

      {/* <Route */}
      {/*   path="/chat" */}
      {/*   exact */}
      {/*   render={(props) => <AboutUs type="chat" {...props} />} */}
      {/* /> */}

      <Route
        path="/visits"
        exact
        render={(props) => <AboutUs type="visits" {...props} />}
      />

      

      
      <Redirect to="/visits" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
